.slowfade-enter{
  opacity: 0.01;
}

.slowfade-enter-active{
  opacity: 1;
  transition: opacity 1000ms linear;
}

.slowfade-exit {
  opacity: 1;
}

.slowfade-exit-active {
  opacity: 0.01;
  transition: opacity 1000ms linear;
}



.fade-enter {
  opacity: 0.01;
}

.fade-enter-active{
  opacity: 1;
  transition: opacity 300ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms linear;
}





.swipe-from-bottom-enter {
  transform: translateY(100%);
}

.swipe-from-bottom-enter-active{
  transform: translateY(0);
  transition: transform 600ms ease;
}

.swipe-from-bottom-exit {
  transform: translateY(0);
}

.swipe-from-bottom-exit-active {
  transform: translateY(100%);
  transition: transform 600ms ease;
}

.swipe-from-bottom-enter-done {
  transform: translateY(0);
}