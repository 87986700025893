html,body{ 
  overflow-y: initial;
}
div {
  box-sizing: border-box;
}

html{
  font-size: 14px; 
}

@media (min-width: 576px) { 
}

@media (min-width: 768px) { 
}

@media (min-width: 1200px) { 
}

@media (min-width: 1600px) {
  html{ font-size: 18px; }
}
th{
  font-weight: normal;
}
#root{
  letter-spacing: .05em;
}

.bg-projekte{
	background-color: $color-projekte;
}

.bg-studio{
	background-color: $color-studio;
}

.bg-etcetera{
	background-color: $color-etcetera;
}

.bg-mobile{
	background-color: $color-mobile;
}

.no-scroll{
  overflow: hidden;
  height: 100%;
}

.link-active{
  text-decoration: underline !important;
}


.clickable{
  cursor: pointer;
}

.clickable:hover{
  text-decoration: underline ;
}


.no-decoration{
  text-decoration: none !important;
}

.smoothscroll{
  -webkit-overflow-scrolling: touch;
}

.button-link{
  display: inline-block;
  font-weight: 500;
  color: #000;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 2rem;
  line-height: 1.2;
  outline: none;
}
.button-link:active, .button-link:focus {
    outline: none;
    border: none;
}