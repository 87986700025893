.Indicator{
  z-index: 100;
}

.DashboardItem{
  transition: opacity 500ms linear;
  z-index: 90;
  opacity: 1;
}
.DashboardItem--open{
  z-index: 99;
  opacity: 1;
}
.Dashboard--zoomed .DashboardItem{
  opacity: 0;
}
.Dashboard--zoomed .DashboardItem.DashboardItem--open{
  opacity: 1;
}

.DashboardItem__Inner{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  overflow: hidden;
  transition: width 1000ms ease;
}

.DashboardItem--open .DashboardItem__Inner {
  width: 177.777%;
}