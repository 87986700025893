.Slide{
  position: relative;
  overflow: hidden;
  padding-left: .25px;
}

.SlideImage{
  height: 100%;
}

.Page__Content--pos-1{
  .SlideImage{
    margin-right: auto;
    margin-left: auto;
  }
}

.Page__Content--pos-2{
  padding-top: 7rem;
  .SlideImage{
    margin-right: auto;
  }
}

.Page__Content--pos-3{
  padding-top: 7rem;
  .SlideImage{
    margin-left: auto;
  }
}



.Slick_Arrow{
  z-index: 500;
  background-color: red;
  position: absolute;
  display: block;
  padding: 0;
  background: none;
  outline: none;
  border: none;
}

.SlickArrow_Prev {
  left: 0;
  bottom: 0;
  right: 50%;
  width: 50%;
  top: 0;
}

.SlickArrow_Next {
  position: absolute;
  right: 0;
  width: 50%;
  left: 50%;
  bottom: 0;
  top: 0;
}



