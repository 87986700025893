.hidepage .Page__Content.slowfade-enter,
.hidepage .Page__Content.slowfade-exit{
  opacity: 0!important;
}



.Page{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #FFF;
}

.Page.hidepage{
  opacity: 0;
}

.Page__Content{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.Page__Content--pos-1{
  .Page__Stage{
    margin-right: auto;
    margin-left: auto;
  }
}

.Page__Content--pos-2{
  padding-top: 7rem;
  .Page__Stage{
    margin-right: auto;
  }
}

.Page__Content--pos-3{
  padding-top: 7rem;
  .Page__Stage{
    margin-left: auto;
  }
}

.Page__Stage{
  z-index: 110;
  position: relative;
}

.Page__Footer{
  z-index: 130;
  position: absolute;
  left:0;
  width: 100%;
  bottom: 0;
  padding: 1rem;

}




.PageArrow{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: 80;
}
.PageArrowTop{
  position: absolute;
  top: 0;
  bottom: 0;

  width: 4rem;
  z-index: 120;
}
.PageArrow-next{
  right: 0;
}
.PageArrow-prev{
  left: 0;
}

