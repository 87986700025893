.m-Page.slowfade-enter .m-OverlayContainer{
  position: fixed;
  left: 0;
  right: 0;
}
.m-Page.slowfade-enter-active .m-OverlayContainer{
  position: fixed;
  left: 0;
  right: 0;
}
.m-Page.slowfade-enter-exit .m-OverlayContainer{
  position: fixed;
  left: 0;
  right: 0;
}
.m-Page.slowfade-enter-exit-active .m-OverlayContainer{
  position: fixed;
  left: 0;
  right: 0;
}


.m-Page__Content{
  position: fixed;
  background-color: #FFF;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.m-Page__Body{
}

.m-Page__Footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem 3.2rem 2rem 1.2rem;
}

.m-PageNextArr{
  position: absolute;
  right: 1.2rem;
  bottom: 2rem;
}

.m-Arrow{
  display: block;
  width: 2rem;
  height: 2rem;
}

.m-PageTease{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transition: height 50ms linear;
}

.m-OverlayContainer{
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  // z-index: 5000;
  transform: translateY(100%);
}

.m-OverlayInner{
  height: 100%;
  overflow-y: scroll;
  overflow-x: none;
}
