.Overlay{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  transition: transform 1000ms ease;
  z-index: 200;
}
.Overlay__Inner{
  overflow-y: auto;
  overflow-x: none;
}

.Overlay--open{
  transform: translateY(0);
}
.Overlay--closed{
  transform: translateY(100%);
}

.Overlay__Tease{
  position: absolute;
  bottom:0;
  left: 0;
  right: 0;
  height: 0;
  transition: height 1000ms ease;
}
.Overlay__Tease.Overlay__Tease--open{
  height: 1rem;
}