.NavBtn{
  display: block;
  position: fixed;
  z-index: 500;
}

.NavBtn__Home{
  top: 1rem;
  left: $grid-gutter-width/2;
}
.NavBtn__Projekte{
  top: 1rem;
  right: $grid-gutter-width/2;
}
.NavBtn__Studio{
  bottom: 1rem;
  left: $grid-gutter-width/2;
}
.NavBtn__EtCetera{
  bottom: 1rem;
  right: $grid-gutter-width/2;
}

.NavContainer{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-top: $header-height-desktop;
  z-index: 100;
}

.NavContainer__Inner{
  transition: background-color 300ms linear;
  overflow-x: none;
  overflow-y: auto;
  padding-top: $header-height-desktop;
}

.NavContainer__List{
  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: $header-height-desktop;
}