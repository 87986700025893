.m-DashboardContainer{
  height: auto;
  overflow-x: hidden;
}

.m-DashboardInner{
  width: 200%;
  transform: translateX(0);
  transition: transform 600ms ease;
}

.m-DashboardInner--left{
  transform: translateX(-50%);
}



.m-DashboardItemContainer{
  position: relative;  
}
.m-DashboardItemImageContainer{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.m-DashboardItemImage{
 width: auto;
 height: 100%;
}

.m-DashboardItemCaption{
  width: 100%;
}