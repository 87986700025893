.m-Logo{
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  a{
    padding: 1rem 1.2rem;
    display: block;
    line-height: 1;
  }
}

.m-NavigationButton{
  z-index: 600;
  position: fixed;
  padding: 1rem 1.2rem;
  left: 0;
  top: 4rem;
  width: auto;
}

.m-NavigationButtonInner{
  width: 2rem;
  height: 2rem;
  overflow:hidden;
}



.m-NavigationBody{
  position: fixed;
  z-index: 500;
  left: 0;
  top: 4rem;
  right: 0;
  bottom: 0;
  overflow-y:scroll;
  overflow-x: hidden;
}


.m-NavigationBody{
  padding: 3rem 1.2rem;
}

.m-NavigationLVL2{
  padding-left: 2rem;
  padding-bottom: 2rem;

  font-size: .75em;
  line-height: 2rem;
}
