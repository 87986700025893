.Image{
  transition: opacity 600ms linear;
}

.Image--loading{
  opacity: 0;
}

.Image--complete{
  opacity: 1;
}